import { Controller } from "stimulus"
const Confetti = require('canvas-confetti');

export default class extends Controller {
  static targets = ["container"]

  connect() {
    this.skew = 1;
    this.confetti = Confetti.create(this.element, { resize: true });
    this._snow();
  }

  _snow() {
    this.skew = Math.max(0.8, this.skew - 0.001);
    this.confetti({
      particleCount: 1,
      startVelocity: 0,
      origin: {
        x: Math.random(),
        y: (Math.random() * this.skew) - 0.2 // since particles fall down, skew start toward the top
      },
      colors: ['#efefef'],
      shapes: ['circle'],
      gravity: this._randomInRange(0.4, 0.6),
      scalar: this._randomInRange(0.4, 1),
      drift: this._randomInRange(-0.4, 0.4)
    });

    requestAnimationFrame(this._snow.bind(this));
  }

  _randomInRange(min, max) {
    return Math.random() * (max - min) + min;
  }


  disconnect() {
    this.confetti.reset();
  }
}



