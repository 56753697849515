import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["overlay"]
  blockScrollListener;

  connect() {

  }

  open(e) {
    e.preventDefault()
    document.body.classList.add("noscroll")
    this.overlayTarget.classList.add("open")

    this.blockScrollListener = function(e) {
      e.preventDefault();
    };
    document.body.addEventListener('touchmove', this.blockScrollListener);
  }

  close(e) {
    e.preventDefault();
    document.body.classList.remove("noscroll");
    this.overlayTarget.classList.remove("open");
    document.body.removeEventListener('touchmove', this.blockScrollListener);
  }
}