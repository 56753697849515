import Siema from 'siema'
import { Controller } from "stimulus"


export default class extends Controller {
  static targets = ["container"]
  gallery;

  connect() {
    this.gallery = new Siema({
      selector: this.containerTarget,
      loop: true
    });
  }

  next(e) {
    e.preventDefault();
    this.gallery.next();
  }

  prev(e) {
    e.preventDefault();
    this.gallery.prev();
  }

  disconnect() {
    this.gallery.destroy();
  }
}



