import { Controller } from "stimulus"
var Packery = require('packery');

export default class extends Controller {
  static targets = []

  connect() {
    var packery = new Packery( this.element, {
      itemSelector: '.grid--item',
      gutter: 0,
      columnWidth: this.element.getElementsByClassName('grid--item')[0],
      transitionDuration: 0
    });
  }
}
